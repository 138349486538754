













import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface ISeparatingComponentsOfAMixtureInput {
  percentIron: number;
  percentNaCL: number;
  percentCaCO3: number;
}

export default defineComponent({
  name: 'LabSeparatingComponentsOfAMixture',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    percentIron: {
      type: Number as PropType<number>,
      default: 20,
    },
    percentNacl: {
      type: Number as PropType<number>,
      default: 50,
    },
    percentCaco3: {
      type: Number as PropType<number>,
      default: 30,
    },
  },
  computed: {
    labData(): ISeparatingComponentsOfAMixtureInput {
      return {
        percentIron: this.percentIron,
        percentCaCO3: this.percentCaco3,
        percentNaCL: this.percentNacl,
      };
    },
    stringData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
