










import LabSeparatingComponentsOfAMixture from '../simulations/LabSeparatingComponentsOfAMixture.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';
import LabTask from './labs/LabTask.vue';
type TaskStateMapping = {
  percentIron: VariableValueType.NumberValue;
  percentNaCL: VariableValueType.NumberValue;
};

export default defineComponent({
  name: 'SeparatingComponentsOfAMixtureSIM',
  components: {LabSeparatingComponentsOfAMixture, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    typedState(): TaskState<TaskStateMapping> {
      return this.taskState as TaskState<TaskStateMapping>;
    },
    percentIron(): number | undefined {
      return this.typedState.getValueBySymbol('percentIron')?.content?.toFloat();
    },
    percentNaCL(): number | undefined {
      return this.typedState.getValueBySymbol('percentNaCL')?.content?.toFloat();
    },
    percentCaCO3(): number | undefined {
      if (!this.percentIron || !this.percentNaCL) {
        return undefined;
      }
      return 100 - this.percentIron - this.percentNaCL;
    },
  },
});
